'use strict';

// const processInclude = require('BASE/../util');

// var formValidation = processInclude(require('BASE/../components/formValidation'));
// var createErrorNotification = processInclude(require('BASE/../components/errorNotification'));
var formValidation = require('BASE/components/formValidation');
var createErrorNotification = require('BASE/components/errorNotification');

module.exports = {
  login: function() {
    $('form.login').submit(function(e) {
      var form = $(this);
      e.preventDefault();
      var url = form.attr('action');
      form.spinner().start();
      $('form.login').trigger('login:submit', e);
      $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: form.serialize(),
        success: function(data) {
          form.spinner().stop();
          if (!data.success) {
            formValidation(form, data);
            $('form.login').trigger('login:error', data);
            $('form.login').find('#btn-login-form').prop('disabled', false);
          } else {
            $('form.login').trigger('login:success', data);
            location.href = data.redirectUrl;
          }
        },
        error: function(data) {
          if (data.responseJSON.redirectUrl) {
            window.location.href = data.responseJSON.redirectUrl;
          } else {
            $('form.login').trigger('login:error', data);
            form.spinner().stop();
          }
        }
      });
      return false;
    });
  },

  register: function() {
    $('form.registration').submit(function(e) {
      var form = $(this);
      e.preventDefault();
      var url = form.attr('action');
      form.spinner().start();
      $('form.registration').trigger('login:register', e);
      $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: form.serialize(),
        success: function(data) {
          form.spinner().stop();
          if (!data.success) {
            formValidation(form, data);
            $('form.registration').trigger('register:error', data);
            $('form.registration').find('#btn-registration').prop('disabled', false);
            $('form.registration').find('#btn-show-hide-pssw').prop('disabled', false);
            $('form.registration').find('#btn-show-hide-confirm-pssw').prop('disabled', false);
            var count=0;
            for(var key in data.fields) {
              $('[name="'+ key + '"]').focus();
              ++count;
            }
          } else {
            $('form.registration').trigger('register:success', data);
            location.href = data.redirectUrl;
          }
        },
        error: function(err) {
          if (err.responseJSON.redirectUrl) {
            window.location.href = err.responseJSON.redirectUrl;
          } else {
            createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
          }

          form.spinner().stop();
        }
      });
      return false;
    });
  },
  msgFnameErrorForm () {
    var registrationElement = document.getElementById('registration-form-fname');
    if(registrationElement){
      registrationElement.addEventListener('input', function () {
        var input = this.value;
        var regex = /^[a-zA-Z ,.'\-]+$/;
  
        if (!regex.test(input)) {
          var parseError = document.getElementById('registration-form-fname').getAttribute('data-parse-error');
            document.getElementById('form-fname-error').innerText = parseError;
        } else {
            document.getElementById('form-fname-error').innerText = "";
        }
      });
    }
    
  },
  msgLnameErrorForm () {
    var registrationElement = document.getElementById('registration-form-lname');
    if(registrationElement){
      registrationElement.addEventListener('input', function () {
        var input = this.value;
        var regex = /^[a-zA-Z ,.'\-]+$/;
  
        if (!regex.test(input)) {
          var parseError = document.getElementById('registration-form-lname').getAttribute('data-parse-error');
            document.getElementById('form-lname-error').innerText = parseError;
        } else {
            document.getElementById('form-lname-error').innerText = "";
        }
      });
    }    
  },
  resetPassword: function() {
    $('.reset-password-form').submit(function(e) {
      var form = $(this);
      var radioBtnFormSelected = $('#id-input').val();
      e.preventDefault();
      var url = form.attr('action');
      form.spinner().start();
      $('.reset-password-form').trigger('login:register', e);
      $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: form.serialize() + '&radioBtnFormSelected=' + radioBtnFormSelected,
        success: function(data) {
          $('#notification-modal').modal('hide');          
          form.spinner().stop();
          if (!data.success) {
            formValidation(form, data);
            $('#submitEmailButton').prop('disabled', false);
          } else {
            $('.alert-danger').hide();
            $('.request-password-title').text(data.receivedMsgHeading);
            $('.request-password-body')
              .empty()
              .append('<p>' + data.receivedMsgBody + '</p>');
              $('#submitEmailButton').prop('disabled', false);
            if (!data.mobile) {
              $('#submitEmailButton')
                .text(data.buttonText)
                .attr('data-dismiss', 'modal');
            } else {
              $('.send-email-btn')
                .empty()
                .html('<a href="' + data.returnUrl + '" class="btn btn-primary btn-block">' + data.buttonText + '</a>');
            }
          }
        },
        error: function() {
          form.spinner().stop();
        }
      });
      return false;
    });
  },

  clearResetForm: function() {
    $('#login .modal').on('hidden.bs.modal', function() {
      $('#reset-password-email').val('');
      $('.modal-dialog .form-control.is-invalid').removeClass('is-invalid');
    });
  },

  radioBtnAgency: function() {
    $('.user-type').on('click', function() {
      var radioBtnId = $(this).get(0).children[1].id;
      if(radioBtnId === 'radio-privato'){
        $('#login .modal').find('#agency').addClass('d-none');
        $('#id-input').val('private');
      }
      if(radioBtnId === 'radio-agency'){
        $('#login .modal').find('#agency').removeClass('d-none');
        $('#id-input').val('agency');
      }

    });
  },
  closeAllertModalChangePssw: function(){
    $(document).ready(function() {      
      $('#password-reset').on('click', function() {        
        $('#requestPasswordResetModal .alert-danger').hide();
      });
    });
  },
  verificationFileGiftCard: function(){
    document.getElementById('fileUpload').addEventListener('change', function(event) {     
      var fileInput = document.getElementById('fileUpload');      
      var file = fileInput.files[0]; 
      var errorMessage = document.getElementById('error-message-file-gift-card');

      if (file) {        
        var img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = function() {
          var width = img.width;
          var height = img.height;
          var maxWidth = 1000; 
          var maxHeight = 1000;  
          
          var errorMessages = [];

          if (width !== height) {
              errorMessages.push("L'immagine deve essere quadrata (proporzione 1:1).");
          }

          if (width < 500 || height < 500) {
              errorMessages.push("L'immagine deve avere una dimensione minima di 500x500 px.");
          }

          if (width > maxWidth || height > maxHeight) {
              errorMessages.push("Le dimensioni dell'immagine superano i 1000px x 1000px.");
          }

          if (errorMessages.length > 0) {
              errorMessage.innerHTML = errorMessages.join('<br>'); 
              errorMessage.style.display = "block";
              fileInput.value = '';
              document.getElementById('fileName').innerText = "Nessun file selezionato";                
          } else {
              errorMessage.style.display = "none";
          }
        }
      };
    });
  },  
  
  formGift: function() {
    $('#myAccountGiftCard').submit(function(e) {
      e.preventDefault(); 
      
      var formData = new FormData(this); 
      var file = $('input#fileUpload');
      formData.append('file',file); 
        
      $.ajax({
        url: $(this).attr('action'),
        type: 'POST',
        data: formData,
        processData: false,  
        contentType: false,             
        success: function(response) {
          if(response.success){
            console.log('Successo:', response);
            $('#myAccountGiftCard')[0].reset();
            document.getElementById('fileUpload').value = '';
            document.getElementById('fileName').innerText = "Nessun file selezionato";
            $('#btnPersonalizeGiftCard').removeAttr('disabled');
            $('#message-gift').html('<div class="success-message" role="alert">Dati salvati con successo!</div>');
            window.location.href = response.redirect;
          }else{
            console.log('Errore:', response.error);
            $('#myAccountGiftCard')[0].reset();
            document.getElementById('fileUpload').value = '';
            document.getElementById('fileName').innerText = "Nessun file selezionato";
            $('#btnPersonalizeGiftCard').removeAttr('disabled');
            $('#message-gift').html('<div class="error-message">Si è verificato un errore durante il salvataggio dei dati. Riprova.</div>');
          } 
          setTimeout(function() {
            $('#message-gift').fadeOut('slow');
        }, 5000);               
        },
        error: function(error) {
            console.log('Errore:', error);
            $('#message-gift').html('<div class="error-message">Si è verificato un errore. Riprova più tardi.</div>');
            setTimeout(function() {
              $('#message-gift').fadeOut('slow');
          }, 5000); 
        }
      });
    });
  },
  
  attachmentAddEventListner: function() {
    document.getElementById('fileUpload').addEventListener('change', function() {
      var fileInput = document.getElementById('fileUpload');
      var fileNameDisplay = document.getElementById('fileName');      
      
      if (fileInput.files.length > 0) {
        fileNameDisplay.textContent = fileInput.files[0].name;
      } else {
        fileNameDisplay.textContent = "Nessun file selezionato";
      }
    });
  }

};
